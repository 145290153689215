import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpLoaderInterceptor } from './core/interceptors/http-loader.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { userReducer } from './store/reducers/user.reducer';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './transloco-loader';
import { packageDetailsReducer } from './store/reducers/package-details.reducer';
import { consignorReducer } from './store/reducers/consignor.reducer';
import { consigneeReducer } from './store/reducers/consignee.reducer';
import { parcelReducer } from './store/reducers/parcel.reducer';
import { binReducer } from './store/reducers/bin.reducer';
import { deviceReducer } from './store/reducers/device.reducer';
import { nodeReducer } from './store/reducers/node.reducer';
import { courierReducer } from './store/reducers/courier.reducer';
import { orderReducer } from './store/reducers/order.reducer';
import { collectionPinReducer } from './store/reducers/collection-pin.reducer';
import { messageReducer } from './store/reducers/message.reducer';
import { packageDetailOptionReducer } from './store/reducers/package-detail-option.reducer';
import { cacheReducer } from "./store/reducers/cache.reducer";
import { environment } from '../environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import {provideEffects} from "@ngrx/effects";
import { CacheEffects } from "./store/effects/cache.effect";

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideAnimations(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    provideStore({
      user: userReducer,
      packageDetails: packageDetailsReducer,
      consignor: consignorReducer,
      consignee: consigneeReducer,
      parcel: parcelReducer,
      bin: binReducer,
      device: deviceReducer,
      node: nodeReducer,
      courier: courierReducer,
      order: orderReducer,
      collectionPin: collectionPinReducer,
      message: messageReducer,
      packageDetailOptions: packageDetailOptionReducer,
      cache: cacheReducer,
    }),
    provideEffects([
      CacheEffects
    ]),
    provideStoreDevtools({
      maxAge: 25, // Retains last 25 states
    }),
    provideTransloco({
      config: {
        availableLangs: ['en', 'pt'],
        defaultLang: 'en',

        reRenderOnLangChange: true,
        prodMode: false,
      },
      loader: TranslocoHttpLoader,
    }),

    importProvidersFrom(MatSelectCountryModule.forRoot('en')),
    importProvidersFrom(KeyboardShortcutsModule.forRoot()),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoaderInterceptor,
      multi: true,
    },
  ],
};
