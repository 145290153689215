import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import * as CacheActions from "../actions/cache.actions";
import {CachingRepositoryService} from "../../services/repos/cache-management/caching.repository.service";
import {refreshLocalCache} from "../actions/cache.actions";


@Injectable()
export class CacheEffects {

  constructor(
    private actions$: Actions,
    private cachingRepositoryService: CachingRepositoryService) {
  }

  getLocalCacheStatus$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CacheActions.getLocalCacheStatus),
        mergeMap(() => this.cachingRepositoryService.getLocalCacheStatus()
          .pipe(
            map(cacheStatus => CacheActions.getLocalCacheStatusSuccess({cacheStatus})),
            catchError(error => of(CacheActions.getLocalCacheStatusFailure({error})))
          )
        )
      );
  });

  getRemoteCacheStatus$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CacheActions.getRemoteCacheStatus),
        mergeMap(action => this.cachingRepositoryService.getRemoteCacheStatus(action.platformTenantCode, action.nodeIdNo)
          .pipe(
            map(cacheStatus => CacheActions.getRemoteCacheStatusSuccess({cacheStatus})),
            catchError(error => of(CacheActions.getRemoteCacheStatusFailure({error})))
          )
        )
      );
  });

  refreshLocalCache$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CacheActions.refreshLocalCache),
      mergeMap(action =>
        this.cachingRepositoryService.refreshLocalCache(action.device).pipe(
          switchMap(() => this.cachingRepositoryService.getLocalCacheStatus().pipe(
              map(cacheStatus =>
                CacheActions.getLocalCacheStatusSuccess({cacheStatus})
              ),
              catchError(error =>
                of(CacheActions.getLocalCacheStatusFailure({error}))
              )
            )
          )
        )
      )
    );
  });
}

