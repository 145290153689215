import {Component, OnInit, inject, isDevMode} from '@angular/core';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {environment} from '../environments/environment';
import {HomeService} from './services/repos/home/home.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
}
