import {createReducer, on} from "@ngrx/store";
import * as CacheActions from "../actions/cache.actions";
import {CacheStatus} from "../../shared/models/local/cache-status";
import {Message, MessageType} from "../../shared/models/local/message";

export interface CacheState {
  error: string | null,
  localStatus: CacheStatus | null,
  remoteStatus: CacheStatus | null,
  loadingMessage: Message | null,
}

export const initialState: CacheState = {
  localStatus: null,
  remoteStatus: null,
  error: null,
  loadingMessage: { message: 'Checking cache status...', messageType: MessageType.INFO },
};


export const cacheReducer = createReducer(
  initialState,
  on(CacheActions.getLocalCacheStatus, (state) => ({
    ...state,
    localStatus: null,
    error: null,
  })),
  on(CacheActions.getLocalCacheStatusSuccess, (state, { cacheStatus }) => ({
    ...state,
    localStatus: cacheStatus,
  })),
  on(CacheActions.getLocalCacheStatusFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(CacheActions.getLocalCacheStatus, (state) => ({
    ...state,
    remoteStatus: null,
    error: null,
  })),
  on(CacheActions.getRemoteCacheStatusSuccess, (state, { cacheStatus }) => ({
    ...state,
    remoteStatus: cacheStatus,
  })),
  on(CacheActions.getRemoteCacheStatusFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(CacheActions.refreshCacheStatusSuccess, (state, { localCacheStatus, remoteCacheStatus }) => ({
    ...state,
    localStatus: localCacheStatus,
    remoteStatus: remoteCacheStatus,
  })),
  on(CacheActions.refreshCacheStatusFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CacheActions.setLoadingMessage, (state, { message }) => ({
    ...state,
      loadingMessage: message,
    }
  )),
);
